<template>
  <div>
    <bread-crumb v-if="data" :selected="data.name"></bread-crumb>
    <div class="network-children__wrap container container__mini2">
      <div class="network-children__title" v-if="data">{{ data.name }}</div>
      <div class="network-children__card">
        <div class="network-children__card--wrap">
          <div class="network-children__card--img">
            <div class="network-children__card--img--wrap" v-if="data.icon">
              <v-lazy-image
                :src="data.icon.url"
                rel="preload"
                width="502"
                height="502"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="network-children__card--descr">
          <div v-if="data" v-html="data.description"></div>
        </div>
      </div>
      <div
        class="network-children__text"
        v-if="data"
        v-html="data.additional_description"
      ></div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
import VLazyImage from "v-lazy-image";
import axios from "axios";

export default {
  components: {
    BreadCrumb,
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
  },
  watch: {
    data: function (newData) {
      //проверка на пустоту объекта
      if (Object.keys(newData).length !== 0) {
        window.mitt.emit("childrenData", this.childrenData);
      }
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      childrens: [],
      products: [],
      data: [],
      childrenData: {
        image: "",
        icon: "",
        title: "",
      },
    };
  },
  methods: {
    async getService() {
      try {
        // let clear_url = this.$route.params.children.replace('/uk/','');
        // clear_url = clear_url.replace('/ru/','');
        // clear_url = clear_url.replace('/','');
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/services/search/" +
            this.$route.params.children,
          config
        );
        this.data = response.data.data[0];
        this.childrens = response.data.data[0].children;
        this.products = response.data.data[0].products;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getBanner() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            this.$route.params.children,
          config
        );
        this.childrenData.image = response.data.data[0].image;
        this.childrenData.icon = response.data.data[0].icon[0].url;
        this.childrenData.title = response.data.data[0].title;
      } catch (e) {
        // alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getService();
    this.getBanner();
  },
  updated() {
    document.title = this.data.name + " - iIT Trading";
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.network-children {
  &__wrap {
    @include property("margin-bottom", 100, 30);

    > p {
      letter-spacing: 0.32px;
      color: #1c1c1c;
    }
  }

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 26, true, 1024, 320);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    margin: 50px 0;
  }

  &__card {
    display: grid;

    @media (min-width: 768px) {
      grid-gap: 68px;
      margin-bottom: 40px;
      grid-template-columns: 1.5fr 1fr;
    }

    &--wrap {
      align-self: flex-start;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      // background: url("~@/assets/images/bg-card.jpg") no-repeat;
      background-size: cover;
      // padding: 10px;
    }

    &--img {
      position: relative;
      overflow: hidden;
      max-width: 100%px;
      width: 100%;

      &--wrap {
        width: 100%;
        padding-bottom: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
        }
      }
    }

    &--descr {
      @include property("padding-top", 75, 20, true, 1280, 1024);
      @include property("padding-bottom", 75, 20, true, 1280, 1024);
      @include property("font-size", 24, 20);
      line-height: calc(31 / 24);
      letter-spacing: 0.48px;

      p {
        @include property("margin-bottom", 55, 20, true, 1280, 768);
      }

      span {
        color: #f9b80e;
      }
    }

    &--link {
      text-align: center;
      padding: 10px 25px;
      background: #f9b80e;
      box-shadow: 0px 0px 15px rgba(249, 184, 14, 0.451);
      border: 1px solid #f9b80e;
      border-radius: 5px;
      font-weight: 700;
      letter-spacing: 0.8px;
      color: #ffffff;
      transition: all 0.3s linear;

      span {
        display: none;
      }

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          background: #ff8c09;

          span {
            display: inline-block;
          }
        }
      }
    }
  }

  &__text {
    font-size: 16px;
    line-height: calc(20 / 16);
    letter-spacing: 0.32px;
    color: #1c1c1c;
  }
}
</style>
